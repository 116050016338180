@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import './variables';
@import './mixins';
@import './custom-styles';
@import './component-styles/card';
@import './component-styles/form';
@import './component-styles/button';
@import './component-styles/badge';

* {
	box-sizing: border-box;
}
body {
	background-color: $bodyBg;
	font-family: 'Nunito';
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

.logo-bg {
	margin-top: rem(68);
}
.v-line {
	margin: rem(16) 0;
}

.subtitle-2 {
	max-width: rem(232);
	margin: 0 auto !important;
}

.d-arrow {
	margin: rem(16) 0;
}

.btn-submit {
	max-width: rem(296);
	padding: rem(14);
}

.multi-input {
	max-width: rem(296);
	display: flex;
	justify-content: space-between;
	margin: 0 auto rem(40) auto;
	position: relative;
	p {
		position: absolute;
		bottom: rem(-23);
		width: 100%;
		text-align: center;
	}
}

.otp-input {
	width: 12%;
	border-radius: 0;
	-webkit-appearance: none;
	appearance: none;
	border: rem(0);
	outline: rem(0);
	border-bottom: rem(1) solid black;
	background: transparent;
	box-shadow: none;
	font-size: rem(24);
	line-height: rem(28);
	text-align: center;
	padding-left: 0;
	padding-right: 0;
	&.has-error {
		border-color: $error;
	}

	// Remove arrow controls
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}
}

.resend-code {
	margin-top: rem(16) !important;
	margin-bottom: rem(12) !important;
	font-size: rem(12) !important;
	line-height: 1.333rem !important;
	font-weight: 400 !important;
	padding: 0 !important;
	color: $linkBlue;
	&:hover {
		background-color: transparent !important;
	}
}

.landingWrap {
	padding: 6vh 32px 32px;
	text-align: center;
	width: 100%;
	max-width: rem(900);
	margin-left: auto;
	margin-right: auto;
}

body .label {
	color: $gray1;
}

.button.link {
	text-decoration: underline;
}

.IconButton.editButton {
	background-color: $bgGray;
	width: 24px;
	height: 24px;
	padding: 0;
	&:hover {
		background-color: $bgGrayDark;
	}
}

.steps-header {
	&.MuiTypography-body2 {
		margin-bottom: 1em;
	}
	position: relative;
	display: block;
	width: fit-content;
	
}

.horizontalLine {
	display: block;
	position: relative;
	margin: 0 0 1em 0;
	width: 100%;
	&::after {
		content: '';
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		width: rem(28);
		height: rem(1);
		margin: 0 auto;
		background-color: $linkBlue;
	}
}

.header-logo {
	height: 28px;
}
.vendor-dashboard {
	&.enrollment {
		.enrollment-tabs {
			.MuiTabs-scroller {
				.MuiTab-root {
					text-transform: none;
					&.Mui-selected {
						color: $linkBlue;
					}
				}
				.MuiTabs-indicator {
					color: $linkBlue;
					background-color: $linkBlue;
					// display: none;
				}
			}
		}
		@media (min-width: 992px) {
			max-width: 1200px !important;
		}
	}
	@media (min-width: 992px) {
		max-width: 100% !important;
	}
}

.inputWrap .MuiInputBase-root {
	input {
		height: auto;
	}
	// Proper padding to achieve 40px height input fields
	input,
	.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
		padding: 10px 14px;
	}
}

label.MuiFormControlLabel-root {
	margin-bottom: 0.5em;
	padding: 0;
}

.MuiRadio-root {
	&:hover {
		.badge {
			border-color: $primary;
			background-color: $primary;
			color: $white;
		}
	}
	.badge {
		font-size: 0.75em;
		font-weight: 400;
		&.checked,
		&.checked:hover {
			border-color: $gray1;
			background-color: $gray1;
			color: $white;
		}
	}
}
.MuiCheckbox-root {
	.badge {
		font-size: 0.75em;
		font-weight: 400;
		&.checked,
		&.checked:hover {
			border-color: $gray1;
			background-color: $gray1;
			color: $white;
		}
	}
}

.MuiOutlinedInput-root {
	&:hover fieldset.MuiOutlinedInput-notchedOutline {
		border-color: $secondary;
	}
	&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
		border-color: $secondary;
	}
}

.inputWrap .MuiAutocomplete-root {
	.MuiOutlinedInput-root {
		padding-top: 0;
		padding-bottom: 0;
		.MuiOutlinedInput-input {
			padding: 10px 14px;
		}
	}
}

// Status based button colors
.vendor-dashboard {
	.MuiButton-root {
		&.enroll {
			background-color: $linkBlue;
			&:hover {
				background-color: lighten($linkBlue, 10%);
			}
		}
		&.pending {
			background-color: $yellowDark;
			&:hover {
				background-color: lighten($yellowDark, 10%);
			}
		}
		&.compliant {
			background-color: $lime;
			&:hover {
				background-color: lighten($lime, 10%);
			}
		}
		&.incomplete {
			background-color: $purple;
			&:hover {
				background-color: lighten($purple, 10%);
			}
		}
		&.noncompliant {
			background-color: $REDLIGHT;
			&:hover {
				background-color: lighten($REDLIGHT, 10%);
			}
		}
	}
}

.loader,
.loader:after {
	border-radius: 50%;
	width: 30px;
	height: 30px;
}
.loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 4px solid rgba(255, 255, 255, 0.2);
	border-right: 4px solid rgba(255, 255, 255, 0.2);
	border-bottom: 4px solid rgba(255, 255, 255, 0.2);
	border-left: 4px solid #ffffff;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

$primary: #0d1743;
$primaryHover: #5a6490;
$secondary: #2ece9e;
$error: #ff3b30;
$lime: #59c802;
$purple: #9348b7;
$yellowDark: #e69e33;
$REDLIGHT: #ff5858;
$bodyBg: #F1F5F8;
$grey: #c4c4c4;
$gray1: #666666;
$white: #ffffff;
$black: #000000;
$linkBlue: #1976d2;
$green: #2ece9e;
$bgGray: #D9E2EC;
$bgGrayDark: #829AB1;
@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.errorCheck {
	border: 1px solid red;
}

.textInput {
	&:focus {
		border-color: $lime;
	}
}

.uploadCard {
	display: block;
	cursor: pointer;
	border: 4px dashed #1976d2;
}
.uploadButton {
	display: block;
	width: 100%;
	background-color: #ffffff;
	border-radius: 16px;
	&:hover {
		background-color: #ffffff;
	}
}

.dialogueBottom {
	align-items: flex-end;
}

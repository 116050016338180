@import '../../styles/variables';
@import '../../styles/mixins';

.container {
	box-sizing: border-box;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	letter-spacing: rem(0.15);
	margin: 0 auto;
	@media (min-width: 992px) {
		max-width: 75%;
	}
}
.center {
	align-items: center;
	text-align: center;
}

.afterArrowImg {
	position: relative;
	margin-bottom: 0;
	.d-arrow {
		margin: 0.5rem;
	}
}

.afterVerticalLine,
.afterVerticalLine.MuiTypography-root {
	position: relative;
	margin-bottom: 1rem;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: rem(28);
		border: 1px solid $primary;
	}
}
.guidelineBGSecPara,
.guidelineBGSecPara.MuiTypography-root {
	margin-top: 6px;
}

.textCenter {
	text-align: center;
}
.textDanger {
	color: $REDLIGHT !important;
}
.link {
	color: $primary;
}
.textMuted,
.textMuted.MuiTypography-root {
	color: $gray1;
	&.errorText {
		color: $error;
	}
}
.errorText {
	color: $error;
}

// Margin

.mb12 {
	margin-bottom: rem(12) !important;
}
.mb20 {
	margin-bottom: rem(20) !important;
}

//Padding
.pl30 {
	padding-left: rem(30);
}

//List
.numberList {
	margin: 0 0 rem(28);
}

.hasIconLeft {
	position: relative;
	padding-left: rem(20);
	.leftIcon {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.MuiTypography-root.imBlueDaBaDee {
	color: $linkBlue;
}


.badge {
	border: rem(1) solid $gray1;
	padding: rem(6) rem(16);
	border-radius: rem(30);
	color: $gray1;
	&.checked {
		color: $primary;
		border-color: $primary;
	}
	&.error {
		color: $REDLIGHT;
		border-color: $REDLIGHT;
	}
	&.invert {
		color: $white;
		background-color: $gray1;
	}
}

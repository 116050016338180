.inputWrap {
	display: flex;
	flex-direction: column;
	label {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: rem(4);
	}

	.MuiInputBase-root {
		border-radius: rem(8);
		margin-bottom: rem(4);
	}
	&.invalidState {
		.inputLabel,
		.inputNote {
			color: $REDLIGHT !important;
		}
		fieldset {
			border-color: $REDLIGHT;
		}
		.MuiCheckbox-root {
			.MuiSvgIcon-root {
				color: $REDLIGHT;
			}
			.badge {
				border-color: $REDLIGHT;
				color: $REDLIGHT;
			}
		}
	}
}
.inputLabel,
.inputNote {
	padding-left: 0.5rem;
}
.inputLabel ~ .MuiFormGroup-root {
	margin-top: 0.5em;
	margin-bottom: 1em;
}
.checkboxLabelMuted {
	.MuiFormControlLabel-label {
		font-size: rem(12);
	}
}
.customCheckBox {
	position: relative;
	margin-bottom: rem(32);
	label {
		margin-right: 0;
		margin-left: rem(5);
	}
	.MuiCheckbox-root {
		position: absolute;
		top: 0;
		left: 0;
	}
	.MuiTypography-root {
		padding-left: rem(35);
	}
}

.MuiFormControlLabel-root {
	.MuiCheckbox-root .MuiSvgIcon-root {
		padding-top: 0;
		margin-top: -3px;
	}
}

.buttonCheck {
	.MuiCheckbox-root {
		width: fit-content;
	}
}

.radioBox {
	.MuiFormControlLabel-root {
		// height: rem(32);
		// margin: rem(12) rem(16) rem(16) 0;
	}
	.css-v2porg-MuiButtonBase-root-MuiRadio-root {
		display: none;
	}
	.css-v2porg-MuiButtonBase-root-MuiRadio-root.Mui-checked ~ .MuiTypography-root {
		.badge {
			border-color: $primary;
			color: $primary;
		}
	}
}

.MuiOutlinedInput-root {
	&:hover fieldset.MuiOutlinedInput-notchedOutline {
		border-color: $lime;
	}

	&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
		border-color: $lime;
	}
}

@import '../../styles/variables';
@import '../../styles/mixins';

.headerTop {
	display: flex;
	align-items: center;
	background: #334E68;
}

.title {
	margin-left: rem(10) !important;
	line-height: 2;
}

div.title > h1 {
	margin: 0;
	text-align: justify;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 5px;
	padding-top: 2px;
}
